<template>
  <div class="roomList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>客房列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>客房名称:</span>
      <el-input placeholder="请输入客房名称" v-model.trim="list.roomName"></el-input>
      <span>所属酒店:</span>
      <el-select
        clearable
        @clear="onClear(1)"
        v-model="list.hotelId"
        placeholder="请选择"
      >
        <el-option
          v-for="item in hotelList"
          :key="item.cateId"
          :label="item.cateName"
          :value="item.cateId"
        >
        </el-option>
      </el-select>
      <span>状态:</span>
      <el-select clearable @clear="onClear(2)" v-model="list.status" placeholder="请选择">
        <el-option
          v-for="item in statusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>销售方式:</span>
      <el-select
        clearable
        @clear="onClear(3)"
        v-model="list.sellWay"
        placeholder="请选择"
      >
        <el-option
          v-for="item in sellWayList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div style="margin-top: 15px">
        <span>是否显示:</span>

        <el-select clearable v-model="list.isShow" placeholder="请选择">
          <el-option
            v-for="item in isShowOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>万旅网显示:</span>
        <el-select clearable v-model="list.wlShow" placeholder="请选择">
          <el-option
            v-for="item in optionsShow"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="onSearch">搜索</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        <el-button
          type="primary"
          @click="$router.push('/addRoom')"
          v-if="$store.state.powerList.indexOf('hotel:room:list:insert') !== -1"
          >添加客房</el-button
        >
      </div>
    </div>
    <!-- -------表格----- -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      @selection-change="onSelectChange"
    >
      <el-table-column align="center" type="selection" width="55"></el-table-column>
      <el-table-column align="center" prop="detp" label="排序" width="120">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button v-else class="button-new-tag" @click="onChangeSort(row)">
            {{ row.dept }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="roomId" label="客房ID" width="120">
      </el-table-column>
      <el-table-column align="center" prop="showImg" label="展示图" width="120">
        <template slot-scope="{ row }">
          <el-image
            style="width: 80px; height: 80px"
            :src="row.showImg"
            :preview-src-list="[row.showImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="roomName" label="客房名称"> </el-table-column>
      <el-table-column align="center" prop="hotelName" label="所属酒店">
      </el-table-column>
      <el-table-column align="center" prop="strSellWay" label="销售方式">
      </el-table-column>
      <el-table-column align="center" prop="strTj" label="是否推荐"> </el-table-column>
      <el-table-column align="center" prop="strStatus" label="状态"> </el-table-column>
      <el-table-column align="center" prop="strUnsubscribe" label="是否退订">
      </el-table-column>

      <el-table-column align="center" prop="strChange" label="是否改期">
      </el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示"> </el-table-column>
      <el-table-column align="center" prop="wlShow" label="万旅网显示"> </el-table-column>
      <el-table-column align="center" label="操作" width="350">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            @click="onUpAndDownRow(row)"
            v-if="
              row.status == 1 &&
              $store.state.powerList.indexOf('hotel:room:list:down') !== -1
            "
            type="primary"
            >下架
          </el-button>
          <el-button
            size="mini"
            @click="onUpAndDownRow(row)"
            v-else-if="
              row.status == 2 &&
              $store.state.powerList.indexOf('hotel:room:list:up') !== -1
            "
            type="primary"
            >上架
          </el-button>
          <el-button
            size="mini"
            type="primary"
            v-if="$store.state.powerList.indexOf('hotel:room:list:update') !== -1"
            @click="$router.push(`/editRoom/${row.roomId}`)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onRoomPrice(row)"
            v-if="$store.state.powerList.indexOf('hotel:room:list:price') !== -1"
            >设置价格</el-button
          >
          <el-dropdown style="margin-left: 10px">
            <el-button size="mini" type="primary">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  style="width: 75px"
                  size="mini"
                  type="primary"
                  @click="onDelRow(row)"
                  v-if="$store.state.powerList.indexOf('hotel:room:list:delete') !== -1"
                  >删除</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  @click="$router.push(`/roomLog/${row.roomId}`)"
                  v-if="$store.state.powerList.indexOf('hotel:room:list:log') !== -1"
                  >查看日志</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  style="width: 75px"
                  @click="onShowAddress(row.roomId)"
                  >查看页面路径</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  v-if="$store.state.powerList.indexOf('hotel:room:list:add') !== -1"
                  @click="addByCodyRoom(row.roomId)"
                  >一键复制</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="onGOGroupBuy(row.roomId)"
                  >去拼团</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="onGOSecKill(row.roomId)"
                  >去秒杀</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="creatCode(row.roomId)"
                  >生成二维码</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <div class="btn">
      <el-button
        type="primary"
        @click="onUpAndDownAll(1)"
        v-if="$store.state.powerList.indexOf('hotel:room:list:up') !== -1"
        >批量上架</el-button
      >
      <el-button
        type="primary"
        @click="onUpAndDownAll(2)"
        v-if="$store.state.powerList.indexOf('hotel:room:list:down') !== -1"
        >批量下架
      </el-button>
      <el-button type="primary" @click="onTj(1)">批量推荐 </el-button>
      <el-button type="primary" @click="onTj(0)">批量取消推荐 </el-button>
      <el-button
        type="primary"
        @click="onUpAndDownAll(0)"
        v-if="$store.state.powerList.indexOf('hotel:room:list:delete') !== -1"
        >批量删除
      </el-button>
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(1)"
        v-if="$store.state.powerList.indexOf('hotel:room:list:wlShow') !== -1"
        >万旅网批量显示</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(0)"
        v-if="$store.state.powerList.indexOf('hotel:room:list:wlShow') !== -1"
        >万旅网批量隐藏</el-button
      >
    </div>
    <el-dialog title="查看酒店页面地址" :visible.sync="showAddress" width="35%">
      <div id="address">
        酒店页面地址:<el-input v-model="roomAddress" readonly id="wrapper"></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="copyinviteUrl"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { cahngeStatus, hotelList, allHotel } from "../../api/hotelList";
import {
  roomList,
  changeSort,
  changeStatus,
  addByCody,
  tj,
  createEr,
  wanlvRoomShow
} from "../../api/room";
import { isActivity } from "../../api/groupBuy";
import { isActivityKill } from "../../api/seckillInfo";
import { createEr2 } from "../../api/erCode";
export default {
  name: "roomList",
  data() {
    return {
      roomAddress: "",
      showAddress: false,
      QRcodeSize: "",
      codeVisible: false,
      roomId: "",
      imgUrl: "",
      tableData: [],
      pagination: {},
      ids: null,
      list: {
        currentPage: 1,
        pageSize: 5,
        hotelId: null,
        roomName: "",
        status: null,
        sellWay: null,
        isShow: -2,
        wlShow: -1,
      },
      hotelList: [],
      statusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      isShowOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      optionsShow: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      sellWayList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "全金额",
        },
        {
          value: 2,
          label: "全积分",
        },
        {
          value: 3,
          label: "积分+金额",
        },
      ],
    };
  },
  watch: {
    "list.hotelId": function () {
      this.$store.commit("setRoomcateId", this.list.hotelId);
    },
  },
  created() {
    this.list.hotelId = this.$store.state.roomcateId;

    this.getHotelList();
    this.getRoomList();
  },
  methods: {
    onChangeAllStatusShow(status){
      if(!this.ids){
        return this.$message({
          type: "warning",
          message: "请选择要修改的内容!",
        });
      }
      // 是否修改
      this.$confirm("是否要批量修改选中客房的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(this.ids)
          const { data } = await wanlvRoomShow({
            roomId: this.ids.join(','),
            wlShow:status,
          });
          if (data.code === 0) {
            this.getRoomList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          
        });

    },
    onShowAddress(id) {
      this.roomAddress =
        "wanXiaoDian/pages/hotel/hotelDetail/hotelDetail?scene=" + id + "_0";
      this.showAddress = true;
    },

    copyinviteUrl() {
      this.$nextTick(() => {
        var range = document.createRange();
        range.selectNode(document.getElementById("wrapper"));
        var selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },

    creatCode(id) {
      this.roomId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr2({
          hotelId: this.roomId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    async onGOSecKill(roomId) {
      const { data } = await isActivityKill({
        prodType: 1,
        prodId: roomId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addSeckill/0/${roomId}/${1}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async onGOGroupBuy(roomId) {
      const { data } = await isActivity({
        prodType: 1,
        prodId: roomId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addGroupBuy/0/${roomId}/${1}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    addByCodyRoom(roomId) {
      this.$confirm("确定一键复制这条收据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await addByCody({
            roomId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getRoomList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },

    onRoomPrice(row) {
      console.log(row);
      this.$router.push(`/roomPrice/${row.roomId}`);
    },
    async getHotelList() {
      const { data } = await allHotel();

      this.hotelList = data.data;
    },
    async getRoomList() {
      const { data } = await roomList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    onSearch() {
      this.getRoomList();
    },
    onClear(status) {
      if (status == 1) {
        this.list.hotelId = null;
      } else if (status == 2) {
        this.list.status = null;
      } else if (status == 3) {
        this.list.sellWay = null;
      }
    },
    onDelRow(row) {
      this.$confirm(`是否要删除---${row.roomName}---这个客房?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: row.roomId + "",
            status: 0,
          });
          if (data.code == 0) {
            this.getRoomList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.ids = null;
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async onUpAndDownRow(row) {
      if (row.status == 1) {
        this.$confirm(`是否要操作这条数据?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await changeStatus({
              ids: row.roomId + "",
              status: 2,
            });
            if (data.code == 0) {
              this.getRoomList();
              this.$message({
                type: "success",
                message: data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else if (row.status == 2) {
        this.$confirm(`是否要操这条数据?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await changeStatus({
              ids: row.roomId + "",
              status: 1,
            });
            if (data.code == 0) {
              this.getRoomList();
              this.$message({
                type: "success",
                message: data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
      this.getRoomList();
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.roomId);
      });
      this.ids = arr;
    },
    async onUpAndDownAll(status) {
      this.$confirm(`是否要操作这些客房?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == null) {
            return this.$message({
              type: "error",
              message: "请选择要操作的数据",
            });
          }
          const { data } = await changeStatus({
            ids: this.ids.join(","),
            status,
          });
          if (data.code == 0) {
            this.getRoomList();
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.ids = null;
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消设置",
          });
        });
    },
    async onTj(status) {
      this.$confirm(`是否要操作这些客房?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == null) {
            return this.$message({
              type: "error",
              message: "请选择要操作的数据",
            });
          }
          const { data } = await tj({
            ids: this.ids.join(","),
            status,
          });
          if (data.code == 0) {
            this.getRoomList();
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.ids = null;
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消设置",
          });
        });
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        roomId: row.roomId,
        dept: row.dept,
      });
      row.inputVisible = false;
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getRoomList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getRoomList();
    },
  },
};
</script>
<style lang="less" scoped>
.roomList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 200px;
    }
    span {
      margin: 0 20px;
    }
    .el-button {
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .btn {
    margin-top: 50px;
  }
}
</style>
